import React,{useEffect, useState} from "react";
import condusef from "../../img/condusef.png";
import sat from "../../img/sat.png";
import asofam from "../../img/asofom.png";
import buro_credito from "../../img/buro_credito.png";
import buro from "../../img/buro.png";
import logo_balnco from "../../img/logo_blanco.png";
import icono_ubi from "../../img/icono-ubi.png";
import icono_mail from "../../img/icono-mail.png";
import icono_phone from "../../img/icono-phone.png";
import icono_what from "../../img/icono-what.png";
import icono_fb from "../../img/icono-fb.png";
import icono_ig from "../../img/icono-ig.png";
import icono_yt from "../../img/icono-yt.png";
import materialized_blueberry from "../../img/materialized-blueberry.gif";
import Pdf from "../portal/pagina/pdf/catycomisiones.pdf";
import momentTime from 'moment-timezone';

export const Footer = () => {
  const [currentYear, setCurrentYear] = useState(2022)
  useEffect(() => {
    setCurrentYear(momentTime(new Date()).tz('America/Mexico_City').format("YYYY"))
  },[])
  return (
    <div>
      <div class="d-none d-sm-none d-md-block p-0 m-0 ">
        <div class="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a
            class="img-client"
            href="https://www.gob.mx/condusef"
            target="_blank"
          >
            <img
              src={condusef}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="130"
            />
          </a>
          <a
            class="img-client"
            href="https://www.sat.gob.mx/home"
            target="_blank"
          >
            <img
              src={sat}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="95"
            />
          </a>
          <a class="img-client" href="https://asofom.mx" target="_blank">
            <img
              src={asofam}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="140"
            />
          </a>
          <a
            class="img-client"
            href="https://www.burodecredito.com.mx"
            target="_blank"
          >
            <img
              src={buro_credito}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="95"
            />
          </a>
          <a class="img-client" href="/buro" target="_blank">
            <img
              src={buro}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none p-0 m-0 ">
        <div class="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a
            class="img-client"
            href="https://www.gob.mx/condusef"
            target="_blank"
          >
            <img
              src={condusef}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="130"
            />
          </a>
          <a
            class="img-client"
            href="https://www.sat.gob.mx/home"
            target="_blank"
          >
            <img
              src={sat}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
        <div class="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a class="img-client" href="https://asofom.mx" target="_blank">
            <img
              src={asofam}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="140"
            />
          </a>
          <a
            class="img-client"
            href="https://www.burodecredito.com.mx"
            target="_blank"
          >
            <img
              src={buro_credito}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
        <div class="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a class="img-client" href="/buro" target="_blank">
            <img
              src={buro}
              alt="PRADO'S"
              class="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
      </div>
      <div class="p-5 footer-text">
        <div class="container">
          <p>
            El Buró de Entidades Financieras contiene información de PROBENSO
            GROUP S.A. SOFOM E.N.R. sobre nuestro desempeño frente a los
            Usuarios, por la prestación de productos y servicios. Te invitamos a
            consultarlo también en la página <a href="https://www.buro.gob.mx" target="_blank">https://www.buro.gob.mx</a> ó a través
            de <a href="https://www.percapita.mx/Buro" target="_blank">https://www.percapita.mx/Buro</a>. Productos ofertados por
            Probenso Group, S.A., SOFOM, E.N.R., quién indica que, no requiere
            autorización de la Secretaría de Hacienda y Crédito Público para su
            constitución y realizar operaciones como Sociedad Financiera de
            Objeto Múltiple Entidad no Regulada y se encuentra sujeta a la
            supervisión de la Comisión Nacional Bancaria y de Valores,
            únicamente para efectos del artículo 56 de la Ley general de
            organizaciones y actividades auxiliares del crédito.
          </p>
          <p>
            Desde $100 hasta $24,500. Periodo mínimo y máximo de repago podrá ser de 91 días hasta 180 días a elección de la solicitud del cliente y al producto de crédito aprobado. APR (Tasa de interés anual) mínima 0% y máxima de 300%. Un ejemplo representativo del costo total del Préstamo considerando la tasa de interés promedio diaria sería el siguiente: Por $2,000 pagarías $2,972.28 incluyendo IVA de los intereses (Cálculo realizado con una tasa de interés diaria del 0.82%. CAT Promedio 300%, (calculado al 31 de enero del 2025). Sin comisiones ni costo extra por liquidaciones anticipadas. Percapita únicamente realiza operaciones en México.
          </p>
          <p>Es obligatorio cumplir con los siguientes requisitos: ser mayor de edad, nacionalidad mexicana, domicilio en territorio nacional y credencial para votar original vigente.</p>
          <p>Percapita únicamente realiza operaciones en México.</p>
          <p>
          Atención a usuarios de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros, para cualquier cuestión respecto de los servicios financieros prestados por Percapita , los datos de contacto de la CONDUSEF son los siguientes: 55 5340-0999  asimismo le informa que la página de internet de la citada Comisión es <a href="https://www.condusef.gob.mx" target="_blank">www.condusef.gob.mx</a> y que el correo electrónico al que podrá enviar sus dudas es el <a href='mailto:asesoria@condusef.gob.mx'> asesoria@condusef.gob.mx.</a>
          </p>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block">
        <footer>
          <div class="bg-footer">
            <div class="container py-5">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div class="text-center">
                    <img src={logo_balnco} alt="PERCAPITA" class="img-fluid" />
                  </div>
                  <div class="row my-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div class="contacto-footer">
                        <h3 class="rExtraBold txt-azul">Contacto</h3>
                        <div class="mt-3">
                        <img
                            src={icono_ubi}
                            alt="Encuentranos en: "
                            className="img-fluid icono-contacto mb-5"
                          />
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Calle Tomás V. Gómez 307
                              </h6> 
                              <h6 className="oRegular">
                              Ladrón de Guevara, Ladrón De Guevara
                              </h6>
                              <h6 className="oRegular">
                              44600 Guadalajara, Jal.
                              </h6>
                            </a>
                          </aside>
                          <img
                            src={icono_ubi}
                            alt="Encuentranos en: "
                            class="img-fluid icono-contacto mb-5"
                          />
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              class="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 class="oRegular">
                              Ures Sur 137 5 
                              </h6>
                              <h6 class="oRegular">
                              Col. Centro, 63000 
                              </h6>
                              <h6 class="oRegular">
                              Tepic, Nayarit.
                              </h6>
                            </a>
                          </aside>
                          <img
                            src={icono_mail}
                            alt="Contactanos en:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular my-3">contacto@percapita.mx</h6>
                          </a>
                          <img
                            src={icono_phone}
                            alt="Contactanos al:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="tel:+523336168059"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular mb-3">33 3616-8059</h6>
                          </a>
                          <img
                            src={icono_what}
                            alt="Contactanos al:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="https://api.whatsapp.com/send?phone=523340094310"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular">33 4009 4310</h6>
                            
                          
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div class="menu-footer">
                        <h3 class="rExtraBold txt-azul">Menú</h3>
                        <div class="mt-3">
                          <a href="/" class="a_none_style text-white">
                            <h6 class="oRegular">Inicio</h6>
                          </a>
                          <a href="/Nosotros" class="a_none_style text-white">
                            <h6 class="oRegular">Nosotros</h6>
                          </a>
                          {/* <a href="/Financiamiento" class="a_none_style text-white">
                            <h6 class="oRegular">Financiamiento</h6>
                          </a> */}
                          {/* <a href="https://app.percapita.mx/" class="a_none_style text-white">
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a> */}
                          <a
                            href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Credito-personal"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito Personal</h6>
                          </a>
                          <a href="/Blog" class="a_none_style text-white">
                            <h6 class="oRegular">Blog</h6>
                          </a>
                          <a href="/Contacto" class="a_none_style text-white">
                            <h6 class="oRegular">Contacto</h6>
                          </a>
                          {/* <a href="https://app.percapita.mx/login" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a> */}
                          <a href="#" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                      <div class="legal">
                        <h3 class="rExtraBold txt-azul">Legal</h3>
                        <div class="mt-3">
                          <a
                            href="/Terminos-y-condiciones"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Términos y Condiciones</h6>
                          </a>
                          <a
                            href="/Aviso-de-privacidad"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Aviso de privacidad</h6>
                          </a>
                          <a
                            //href='https://percapita.mx/catycomisiones/catycomisiones.pdf'
                            href="/Cat-y-comisiones"
                            target="_blank"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">CAT y Comisiones</h6>
                          </a>
                          <a href="/Faq" class="a_none_style text-white">
                            <h6 class="oRegular">Preguntas frecuentes</h6>
                          </a>
                          <a
                            href="/Derechos-arco"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Derechos de Arco</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div class="redes">
                        <h3 class="rExtraBold txt-azul">Síguenos</h3>
                        <div class="mt-5 text-center">
                          <a
                            href="https://www.facebook.com/PercapitaPrestamos"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_fb}
                              alt="FB: PERCAPITA"
                              class="img-fluid me-3"
                            />
                          </a>
                          {/* <a href="#" class="a_none_style text-white" target="_blank" rel="noopener noreferrer">
                            <img src={icono_ig} alt="IG: PERCAPITA" class="img-fluid me-3" />
  </a>*/}
                          <a
                            href="https://www.youtube.com/channel/UCsqIIu_msdAzHDzHDdUK7Qg"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_yt}
                              alt="YT: PERCAPITA"
                              class="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-footer" />
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <h5 class="oRegular txt-derechos">
                        © {currentYear} Percapita. Todos Los Derechos Reservados.
                      </h5>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div class="text-end">
                        <img
                          src={materialized_blueberry}
                          alt="Materialized by Blueberry"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <footer>
          <div class="bg-footer">
            <div class="container py-5">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div class="text-center">
                    <img src={logo_balnco} alt="PERCAPITA" class="img-fluid" />
                  </div>
                  <div class="row my-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                      <div class="contacto-footer mx-4">
                        <h3 class="rExtraBold txt-azul">Contacto</h3>
                        <div class="mt-3">
                          
                        <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Calle Tomás V. Gómez 307
                              </h6> 
                              <h6 className="oRegular">
                              Ladrón de Guevara, Ladrón De Guevara
                              </h6>
                              <h6 className="oRegular">
                              44600 Guadalajara, Jal.
                              </h6>
                            </a>
                          </aside>
                          <div className="border-top my-3"></div>
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              class="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 class="oRegular">
                              Ures Sur 137 5 
                              </h6>
                              <h6 class="oRegular">
                              Col. Centro, 63000 
                              </h6>
                              <h6 class="oRegular">
                              Tepic, Nayarit.
                              </h6>
                            </a>
                          </aside>
                          <a
                            href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular my-3">contacto@percapita.mx</h6>
                          </a>
                          <a
                            href="tel:+523336168059"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular mb-3">33 3616-8059</h6>
                          </a>
                          <img
                            src={icono_what}
                            alt="Contactanos al:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="https://api.whatsapp.com/send?phone=523340094310"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular">33 4009 4310</h6>
                          
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2 mb-4">
                      <div class="menu-footer mx-4">
                        <h3 class="rExtraBold txt-azul">Menú</h3>
                        <div class="mt-3">
                          <a href="/" class="a_none_style text-white">
                            <h6 class="oRegular">Inicio</h6>
                          </a>
                          <a href="/Nosotros" class="a_none_style text-white">
                            <h6 class="oRegular">Nosotros</h6>
                          </a>
                          {/* <a href="/Financiamiento" class="a_none_style text-white">
                            <h6 class="oRegular">Financiamiento</h6>
                          </a> */}
                          {/* <a href="https://app.percapita.mx/" class="a_none_style text-white">
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a> */}
                          <a
                            href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Credito-personal"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito Personal</h6>
                          </a>
                          <a href="/Blog" class="a_none_style text-white">
                            <h6 class="oRegular">Blog</h6>
                          </a>
                          <a href="/Contacto" class="a_none_style text-white">
                            <h6 class="oRegular">Contacto</h6>
                          </a>
                          {/* <a href="https://app.percapita.mx/login" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a> */}
                          <a href="#" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                      <div class="legal mx-4">
                        <h3 class="rExtraBold txt-azul">Legal</h3>
                        <div class="mt-3">
                          <a
                            href="/Terminos-y-condiciones"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Términos y Condiciones</h6>
                          </a>
                          <a
                            href="/Aviso-de-privacidad"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Aviso de privacidad</h6>
                          </a>
                          <a
                            //href='https://percapita.mx/catycomisiones/catycomisiones.pdf'
                            href="/Cat-y-comisiones"
                            target="_blank"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">CAT y Comisiones</h6>
                          </a>
                          {/* <a href="#" class="a_none_style text-white">
                            <h6 class="oRegular">Políticas de Cookies</h6>
                          </a> */}
                          <a href="/Faq" class="a_none_style text-white">
                            <h6 class="oRegular">Preguntas frequentes</h6>
                          </a>
                          <a
                            href="/Derechos-arco"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Derechos Arco</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div class="redes mx-4">
                        <h3 class="rExtraBold txt-azul">Síguenos</h3>
                        <div class="mt-3">
                          <a
                            href="https://www.facebook.com/PercapitaPrestamos"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_fb}
                              alt="FB: PERCAPITA"
                              class="img-fluid me-3"
                            />
                          </a>
                          {/* <a href="#" class="a_none_style text-white" target="_blank" rel="noopener noreferrer">
                            <img src={icono_ig} alt="IG: PERCAPITA" class="img-fluid me-3" />
                          </a> */}
                          <a
                            href="https://www.youtube.com/channel/UCsqIIu_msdAzHDzHDdUK7Qg"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_yt}
                              alt="YT: PERCAPITA"
                              class="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-footer" />
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <h5 class="oRegular txt-derechos text-center">
                        © 2022 Percapita. Todos Los <br /> Derechos Reservados.
                      </h5>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div class="text-center">
                        <img
                          src={materialized_blueberry}
                          alt="Materialized by Blueberry"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
